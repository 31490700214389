<template>
  <v-row class="row--small top-bar__tabs mt-0 px-0 mx-0">
    <v-col cols="12" class="py-0 px-0 d-flex">
      <v-tabs prev-icon="$chevronleft" next-icon="$chevronright" center-active>
        <v-tab
          v-ripple="false"
          :to="`/instances/${instance.id}/general`"
          class="p-1 font-weight-600 top-bar__item"
          >{{ $t('heading.instance.tab.siteDetails') }}</v-tab
        >
        <v-tab
          v-if="$store.state.home.app.report_provider_enabled && (instance.getUserPrivileges('general.view_reports'))"
          v-ripple="false"
          :to="`/instances/${instance.id}/monitoring`"
          class="p-1 font-weight-600 top-bar__item"
        >
          {{ $t('heading.instance.tab.monitoring') }}
          <v-tooltip
            v-if="hasReportProviderWarning"
            open-delay="150"
            bottom
            transition="custom-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                label
                class="warning--text warning lighten-5 p-3 px-2 py-1 ml-2"
                @click.native="openJavaScriptCodeModal"
                :class="instance.health_checks.report_provider.status == 'error' ? 'cursor-pointer' : ''"
              >
                <v-icon size="16" class="warning--text">$alertwarning</v-icon>
              </v-chip>
            </template>
            {{ getReportProviderWarningMessage || $t('tooltip.instance.tab.monitoring.warning') }}
          </v-tooltip>
        </v-tab
        >
        <v-tab
          v-ripple="false"
          :to="`/instances/${instance.id}/backups`"
          class="p-1 font-weight-600 top-bar__item"
          v-if="instance.getUserPrivileges('wordpress.manage_backups')"

        >
          {{ $t('heading.instance.tab.backups') }}
          <v-tooltip
            open-delay="150"
            bottom
            transition="custom-tooltip"
            v-if="instance.hasAutomaticBackup() && instance.hasRecentBackup()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                label
                class="success--text success lighten-5 p-3 px-2 py-1 ml-2"
              >
                <v-icon size="16" class="success--text">$alertok</v-icon>
              </v-chip>
            </template>
            <span v-if="instance.backup_details.length">
              {{ $t('tooltip.instance.tab.backup.recent') }}
            </span>
            <span v-else>
              {{ $t('tooltip.instance.tab.backup.scheduled') }}
            </span>
          </v-tooltip>
          <v-tooltip v-else open-delay="150" bottom transition="custom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                label
                class="error--text error lighten-5 p-3 px-2 py-1 ml-2"
              >
                <v-icon size="16" class="error--text">$alertwarning</v-icon>
              </v-chip>
            </template>
            <span v-if="!instance.hasRecentBackup()">
              {{ $t('tooltip.instance.tab.backup.empty') }}
            </span>
            <span v-if="!instance.hasAutomaticBackup()">
              {{ $t('tooltip.instance.tab.backup.disabled') }}
            </span>
          </v-tooltip>
        </v-tab>
        <v-tab
          v-ripple="false"
          :to="`/instances/${instance.id}/plugins`"
          class="p-1 font-weight-600 top-bar__item"
          v-if="instance.getUserPrivileges('wordpress.manage_plugins')"
        >
          {{ $t('heading.instance.tab.plugins') }}
          <v-tooltip
            v-if="instance.info.plugins.errors"
            open-delay="150"
            bottom
            transition="custom-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                label
                class="error--text error lighten-5 p-3 px-2 py-1 ml-2"
              >
                {{ instance.info.plugins.errors }}
                <v-icon size="16" class="error--text ml-1">
                  $alertwarning
                </v-icon>
              </v-chip>
            </template>
              {{ $t('tooltip.instance.tab.plugins.update', {count: instance.info.plugins.errors}) }}
          </v-tooltip>
          <v-tooltip
            open-delay="150"
            bottom
            transition="custom-tooltip"
            v-else
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                label
                class="success--text success lighten-5 p-3 px-2 py-1 ml-2"
              >
                <v-icon size="16" class="success--text">$alertok</v-icon>
              </v-chip>
            </template>
            {{ $t('tooltip.instance.tab.plugins.noUpdate') }}
          </v-tooltip>
        </v-tab>
        <v-tab
          v-ripple="false"
          :to="`/instances/${instance.id}/advanced`"
          class="p-1 font-weight-600 top-bar__item"
        >
          {{ $t('heading.instance.tab.advanced') }}
          <v-tooltip
            open-delay="150"
            bottom
            transition="custom-tooltip"
            v-if="
              (instance.debugMode || instance.maintenanceMode) &&
              !instance.staging
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                label
                class="warning--text warning lighten-5 p-3 px-2 py-1 ml-2"
              >
                <v-icon size="16" class="warning--text">$alertwarning</v-icon>
              </v-chip>
            </template>
            {{ $t('tooltip.instance.tab.advanced.mode', {mode: debugOrMaintenanceModeText}) }}
          </v-tooltip>
        </v-tab>
      </v-tabs>
      <div class="d-flex align-center" v-if="!$vuetify.breakpoint.smAndDown">
        <router-link
          class="d-flex align-center"
          :to="`/hosting/summary?id=${this.instance.service_id}`"
        >
          <span class="p-1 info--text d-flex align-center">
            <v-icon size="16" class="mr-2" color="info">$externallink</v-icon>
            {{ $t('general.hosting') }}
          </span>
        </router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    instance: Object,
  },
  computed: {
    debugOrMaintenanceModeText() {
      if (this.instance.debugMode && this.instance.maintenanceMode) {
        return this.$t('tooltip.instance.tab.advanced.mode.debugAndMaintenance');
      } else if (this.instance.debugMode) {
        return this.$t('tooltip.instance.tab.advanced.mode.debug');
      } else if (this.instance.maintenanceMode) {
        return this.$t('tooltip.instance.tab.advanced.mode.maintenance');
      }
      return "";
    },
    hasReportProviderWarning() {
      return this.instance.health_checks.report_provider.status === 'error'
        || this.instance.health_checks.report_provider.status === 'unknown'
    },
    getReportProviderWarningMessage() {
      return this.instance.health_checks.report_provider.message;
    },
  },
  methods: {
    openJavaScriptCodeModal() {
      if (this.instance.health_checks.report_provider.status === 'error') {
        this.$emit('openModal');
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.top-bar__tabs {
  margin-left: -24px;
  margin-right: -24px;
}

.top-bar {
  &__item {
    &.v-tab--active {
      color: map-get($primary, darken1);
    }
  }
}

.v-tab {
  min-width: unset;
  padding-left: 4px;
  padding-right: 4px;
}

.theme--light.v-tabs::v-deep .v-slide-group__prev,
.theme--light.v-tabs::v-deep .v-slide-group__next {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>