<template>
  <div class="skeleton px-4 py-4 d-flex">
      <v-skeleton-loader type="avatar" />
      <v-skeleton-loader width="100px" type="text@2" class="ml-3"/>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.skeleton {
    width: 473px;
}
</style>