<template>
  <v-card class="basic-modal" elevation="4" width="560px">
    <v-card-title class="d-flex justify-space-between">
      <h5 class="">
        <v-icon size="24" class="mr-4" color="primary">$lockoutline</v-icon>
        {{ $t('heading.instance.modal.code.title') }}
      </h5>

      <v-btn icon x-small @click="$emit('modal-close')">
        <v-icon size="24">$close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pb-4">
      <p class="p-1">{{ $t('heading.instance.modal.code.message') }}</p>
      <div class="recovery-code-box">
        <pre class="wrapped-text">{{ instance.health_checks.report_provider.code }}</pre>
        <copy-text-button
          :text="instance.health_checks.report_provider.code"
          :color="'primary'"
          :size="24"
        />
      </div>

    </v-card-text>
    <v-card-actions class="d-flex flex-wrap pb-8">
      <div class="w-100">
        <v-btn
          color="primary"
          x-large
          block
          elevation="0"
          @click="closeModal(true)"
        >{{ $t('button.back') }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import CopyTextButton from "../buttons/CopyTextButton.vue";

export default {
  components: {
    CopyTextButton
  },
  props: {
    instance: Object
  },
  data() {
    return {

      step: 1,
      code: "",
      recoveryCode: ["werwersdf"],
      password: "",
      showPassword: false,
      loading: false,
      confirmedCloseModal: false,
    };
  },
  methods: {
    closeModal(status) {
      this.$emit("modal-close", status);
    }
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    .v-card__actions {
      padding-top: 16px;
    }

    .v-card__title {
      min-height: 96px;
    }

    .v-card__text {
      font-weight: $font-weight-light;
      -webkit-line-clamp: initial;
    }
  }
}

.checkbox::v-deep {
  .v-input--checkbox {
    .v-input__slot {
      max-height: 24px;
      min-height: unset;
    }
  }
}

label + .v-input {
  margin-top: 10px;
}

.recovery-code-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: start;
  color: var(--v-primary-base);
  background: rgba(7, 192, 126, 0.05);
  border-radius: 6px;
  padding: 6px;
}

.v-input::v-deep {
  .v-text-field__details {
    margin-top: 2px;
    margin-bottom: 0px !important;
  }
}

b {
  font-weight: $font-weight-semibold;
  color: map-get($text, heading);
}

.checkbox-label {
  cursor: pointer;
}

.input-group {
  .v-input + .v-input {
    margin-left: 16px;
  }
}

.v-form .last-child {
  .v-input {
    &.v-text-field {
      margin-bottom: 0px;
    }
  }
}

.v-form .v-input::v-deep.v-text-field {
  margin-bottom: 16px;
}

.v-card::v-deep {
  .v-card__title {
    flex-wrap: nowrap;

    .v-btn {
      align-self: flex-start;
    }
  }
}

.wrapped-text {
  word-wrap: break-word;
  white-space: pre-wrap;
  width: 430px;
}
</style>
