<template>
  <div class="d-flex skeleton-container">
    <div class="d-flex flex-column mr-10" v-for="index in 5" :key="index">
      <v-skeleton-loader class="tab" type="header, text" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.v-skeleton-loader::v-deep {
  width: 48px;
  margin-bottom: -12px;
  .v-skeleton-loader {
    &__header {
      width: 48px;
      height: 8px;
      border-radius: 3px;
      margin-top: 19px;
    }
    &__text {
      border-radius: 32px;
      height: 3px;
      width: 48px;
      margin-bottom: 0px;
      margin-top: 18px;
    }
  }
}
</style>