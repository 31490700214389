<template>
  <div class="px-6">
    <v-container class="mt-10 top-bar__container px-0">
      <v-row
        class="flex-row justify-space-between flex-nowrap mx-0 pb-12"
        :class="$vuetify.breakpoint.mobile ? '' : ''"
      >
        <div class="d-flex align-center top-bar-title">
          <router-link
            to="/"
            class="mr-6 back-button"
            v-if="!$vuetify.breakpoint.mobile"
          >
            <v-icon size="24" class="heading--text">$arrowleft</v-icon>
          </router-link>
          <!-- <instance-avatar
            v-if="!$vuetify.breakpoint.mobile"
            :fetching="true"
          ></instance-avatar> -->
          <div
            class="top-bar-title__container"
            :class="$vuetify.breakpoint.mobile ? 'ml-0' : 'ml-0'"
          >
            <div class="d-flex skeleton-loader title flex-column">
              <v-skeleton-loader type="header" class="header" />
              <v-skeleton-loader type="text, chip@2" class="subheader" />
            </div>
          </div>
        </div>

        <div class="d-flex justify-start justify-lg-end align-center">
          <base-icon-button-skeleton
            v-for="index in $vuetify.breakpoint.mobile ? 2 : 5"
            :key="index"
          />
        </div>
      </v-row>

      <instance-top-bar-tabs-skeleton />
    </v-container>
  </div>
</template>

<script>
import BaseIconButtonSkeleton from "../buttons/BaseIconButtonSkeleton.vue";
import InstanceTopBarTabsSkeleton from "./InstanceTopBarTabsSkeleton.vue";
// import InstanceAvatar from "../InstanceAvatar.vue";

export default {
  components: {
    BaseIconButtonSkeleton,
    InstanceTopBarTabsSkeleton,
    // InstanceAvatar,
  },
};
</script>

<style lang="scss" scoped>
.skeleton-loader.title {
  .v-skeleton-loader::v-deep {
    &.subheader {
      display: flex;
      align-items: center;
      max-width: 100%;
      width: 300px;
      .v-skeleton-loader__text {
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 3px;
        width: 182px;
        height: 10px;
        margin-right: 16px;
      }
      .v-skeleton-loader__chip {
        border-radius: 16px;
        height: 20px;
        width: 68px;
        + .v-skeleton-loader__chip {
          margin-left: 8px;
        }
      }
    }
    &.header {
      .v-skeleton-loader__header {
        margin-top: 4px;
        margin-bottom: 4px;
        border-radius: 3px;
        width: 150px;
        height: 32px;
      }
    }
  }
}
</style>
