<template>
  <div class="d-flex flex-column skeleton-loader ml-4">
      <v-skeleton-loader type="chip" />
      <v-skeleton-loader type="text"/>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.v-skeleton-loader::v-deep {
    .v-skeleton-loader {
        &__chip {
            width: 24px;
            height: 24px;
            margin: 11px;   
            border-radius: 3px; 
        }
        &__text {
            height: 6px;
            width: 48px;
            border-radius: 3px;
            margin: 4px 0;

        }
    }
}
</style>