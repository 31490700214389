<template>
  <div>
    <instance-top-bar
      :fetching="fetching"
      :instance="instance"
      :key="`instance-tb-${instance.id}`"
      @action-button-wplogin="showSsoModal"
      @action-button-staging="showStagingModal"
      @action-button-retry-staging="showRetryStagingModal"
      @action-button-cancel-staging="cancelStaging"
      @action-button-push-to-live="showPushToLiveModal"
      @action-button-update="showUpdateModal"
      @action-button-clearcache="showClearCacheModal"
      @action-button-delete="showDeleteModal"
      @action-button-copy="showCopyInstanceModal"
      @instance-published="onInstancePublished"
    ></instance-top-bar>

    <v-divider />

    <div class="px-6">
      <router-view
        v-if="pageMounted && instance.isActive"
        :key="`instance-rv-${instance.id}`"
        :fetching="fetching"
        :instance="instance"
        @backups-reload="recheckBackupStatus"
        @plugins-reload="recheckPluginStatus"
        @action-button-wplogin="showSsoModal"
        @action-button-update="showUpdateModal"
        @action-button-install-woocommerce="showInstallWoocommerceModal"
      ></router-view>

      <v-dialog
        :persistent="modalOptions.persistent"
        v-model="modalOptions.open"
        ref="dialog"
        transition="custom-dialog-transition"
      >
        <div
          class="card-overlay"
          v-if="!modalOptions.persistent"
          @click="modalOptions.open = !modalOptions.open"
        />
        <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
        <basic-modal
          style="width: 560px"
          :modalOptions="modalOptions"
          @modal-close="modalClose"
          :key="modalRender"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";

import InstanceTopBar from "../../components/topbar/InstanceTopBar.vue";

import InstanceActionsMixin from "@/mixins/InstanceActionsMixin";
import BasicModal from "@/components/modal/BasicModal";
import { OpenUpdatesModalMixin } from "../../mixins/OpenModalAction";
// import i18next from "i18next";
import store from "@/store/store";

export default {
  mounted: async function () {
    this.mountInstancePage();
  },
  beforeRouteEnter(to, from, next) {
    if (+to.params.id) {
      next();
    } else {
      next((vm) => {
        vm.$router.push({ name: "Error" });
      });
    }
  },
  computed: {
    instanceId: function () {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      instance: {},
      fetching: true,
      pageMounted: false,
    };
  },
  mixins: [InstanceActionsMixin, OpenUpdatesModalMixin],
  components: {
    InstanceTopBar,
    BasicModal,
  },
  watch: {
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
    "$route.params.id": function (newValue, oldValue) {
      if (newValue == oldValue) return;
      this.$nextTick(() => {
        this.dismountInstancePage();
        this.mountInstancePage();
      });
    },
  },
  methods: {
    dismountInstancePage() {
      this.$off("instance-update-in-progress");
      this.$root.$off("instance-update-failed");
      this.$root.$off("instance-update-finished");
      this.$root.$off("staging-install-failed");
      this.$root.$off("staging-install-finished");
      this.$off("instance-push-to-live-in-progress");
      this.$root.$off("push-to-live-finished");
      this.$root.$off("push-to-live-failed");
      this.$off("instance-deleted");
      this.$off("staging-created");
      this.$off("staging-install-retry");
      this.$off("staging-cancel");
      this.$off("staging-cancel-failed");
      this.$off("staging-deleted");
      this.$root.$off("instance-changed");
      this.$store.dispatch('setInstanceName', "");
    },
    async mountInstancePage() {
      this.$on("instance-update-in-progress", (instanceId) => {
        this.updateAsyncStatus(instanceId, "update", "in_progress");
      });
      this.$root.$on("instance-update-failed", (instance) => {
        this.updateAsyncStatus(instance.id, "update", "failed");
      });
      this.$root.$on("instance-update-finished", (instance) => {
        this.updateAsyncStatus(instance.id, "update", "finished");
        this.instance.status.wordpress = instance.version;
      });
      this.$root.$on("staging-install-failed", (instance) => {
        this.updateAsyncStatus(instance.id, "installation", "failed");
      });
      this.$root.$on("staging-install-finished", (instance) => {
        this.updateAsyncStatus(instance.id, "installation", "finished");
        for (const [key, item] of this.instance.subInstances.entries()) {
          if (item.id === instance.id) {
            this.instance.subInstances[key] =
              Api.instances().instanceResource(instance);
            this.instance.subInstances = [...this.instance.subInstances];
            break;
          }
        }
      });
      this.$on("instance-push-to-live-in-progress", (instance) => {
        this.updateAsyncStatus(instance.id, "push_to_live", "in_progress");
        this.updateAsyncStatus(
          instance.staging,
          "pull_from_staging",
          "in_progress"
        );
      });
      this.$root.$on("push-to-live-finished", (instance) => {
        this.updateAsyncStatus(instance.id, "push_to_live", "finished");
        this.updateAsyncStatus(
          instance.staging,
          "pull_from_staging",
          "finished"
        );
      });
      this.$root.$on("push-to-live-failed", (instance) => {
        this.updateAsyncStatus(instance.id, "push_to_live", "failed");
        this.updateAsyncStatus(instance.staging, "pull_from_staging", "failed");
      });
      this.$on("instance-deleted", (instance) => {
        if (instance.staging) {
          instance.parentInstance.unsetSubinstance(instance.id);
          this.$root.$emit("instance-changed", instance.parentInstance);
          return;
        }
        this.$router.push({ path: "/" });
      });
      this.$on("staging-created", (instance) => {
        this.instance.subInstances.push(
          Api.instances().instanceResource(instance)
        );
      });
      this.$on("staging-install-retry", (stagingSubinstance) => {
        for (const [key, item] of this.instance.subInstances.entries()) {
          if (item.id === stagingSubinstance.id) {
            this.instance.subInstances[key] =
              Api.instances().instanceResource(stagingSubinstance);
            this.instance.subInstances = [...this.instance.subInstances];
            break;
          }
        }
      });
      this.$on("staging-cancel", (stagingSubinstance) => {
        this.updateAsyncStatus(
          stagingSubinstance.id,
          "installation",
          "in_progress"
        );
      });
      this.$on("staging-cancel-failed", (stagingSubinstance) => {
        this.updateAsyncStatus(stagingSubinstance.id, "installation", "failed");
      });
      this.$on("staging-deleted", (stagingSubinstance) => {
        for (const [key, item] of this.instance.subInstances.entries()) {
          if (item.id === stagingSubinstance.id) {
            this.instance.subInstances.splice(key, 1);
            break;
          }
        }
      });
      //
      this.$root.$on("instance-changed", (instance) => {
        if (this.instance.id && this.instance.id !== instance.id) {
          let routeParams = { ...this.$route.params };
          routeParams.id = instance.id;
          this.$router.push({
            name: this.$route.name,
            params: routeParams,
          });
          this.fetch();
        }
      });
      //

      await this.fetch();

      if (!this.instance.isActive) {
        this.$router.push("/instances");
        return;
      }
      if (this.instance.service_status == "suspended") {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: this.$t('message.serviceSuspended')
        });
        this.$router.push("/instances");
        return;
      }
      await this.fetchMaintenanceMode();
      await this.fetchDebugMode();

      this.pageMounted = true;
    },
    fetch: async function () {
      this.fetching = true;

      Api.cached()
        .get("/labels")
        .then((response) => {
          this.setLabels(response.data.data);
        })
        .catch(() => {});

      await Api.instances()
        .get(this.instanceId)
        .then((instance) => {
          this.instance = instance;
          this.setHeadTitle();
        })
        .catch(() => {
          this.$router.push("/page-not-found");
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    setHeadTitle() {
      this.$store.dispatch('setInstanceName', this.instance.title);

      document.title = this.$t(`title.${this.$route.meta.title}`, {
        app_name: store.state.home.app.name,
        instance_name: this.instance.title
      });
    },
    setLabels(data) {
      this.$store.dispatch("setUserLabels", data);
    },
    findSubinstance(id) {
      if (this.instance.id == id) {
        return this.instance;
      }
      for (const subinstance of this.instance.subInstances) {
        if (subinstance.id == id) {
          return subinstance;
        }
      }
      return {};
    },
    recheckBackupStatus(backups) {
      let new_backup = null;
      let new_backup_recent = null;
      let newestBackup = null;
      if (backups && backups.length) {
        new_backup = true;
        for (const backup of backups) {
          if (backup.date > newestBackup) {
            newestBackup = backup.date;
          }
        }
        if (newestBackup > new Date().getTime() - 1 * 24 * 60 * 60 * 1000) {
          new_backup_recent = true;
        }
      }
      this.instance.status.backup = new_backup;
      this.instance.status.backup_recent = new_backup_recent;
    },
    recheckPluginStatus(plugins) {
      if (!this.instance.info) {
        return;
      }
      let pluginErrors = 0;
      if (plugins && plugins.length) {
        for (const plugin of plugins) {
          if (plugin.update == "available") {
            pluginErrors++;
          }
        }
      }
      this.instance.info.plugins.errors = pluginErrors;
    },
    updateAsyncStatus(subnstanceId, action, status) {
      let subinstance = this.findSubinstance(subnstanceId);
      if (subinstance.id) {
        subinstance.async_status[action] = status;
      }
    },
    onInstancePublished(instance) {
      this.instance = Api.instances().instanceResource(instance);
    },
    fetchDebugMode() {
      if (!this.instance.staging) {
        Api.cached()
          .get(`/instances/${this.instance.id}/wordpress/debug-mode`)
          .then((response) => {
            this.instance.setDebugMode(response.data.status);
          })
          .catch((error) => {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: Api.getErrorMessage(error),
            });
          });
      }
    },
    fetchMaintenanceMode() {
      if (!this.instance.staging) {
        Api.cached()
          .get(`/instances/${this.instance.id}/wordpress/maintenance-mode`)
          .then((response) => {
            this.instance.setMaintenanceMode(response.data.status);
          })
          .catch((error) => {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: Api.getErrorMessage(error),
            });
          });
      }
    },
  },
};
</script>

<style></style>
